<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('unihall_applications_title')" :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('unihall_applications_title')" :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.surname" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input v-model="datatable.queryParams.filter.student_number" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('national_id')">
                            <b-form-input v-model="datatable.queryParams.filter.national_id" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('gender')">
                            <gender-selectbox v-model="datatable.queryParams.filter.gender"></gender-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('payment_status')">
                            <b-form-select :options="[{ text: $t('yes'), value: 'E' }, { text: $t('no'), value: 'H' }]"
                                v-model="datatable.queryParams.filter.payment_status"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year">
                            </academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('status')">
                            <b-form-select
                                :options="[{ text: $t('status_approved'), value: 'approved' }, { text: $t('status_waiting_payment'), value: 'waiting_payment' },
                                { text: $t('status_declined'), value: 'declined' }, { text: $t('status_waiting'), value: 'waiting' }]"
                                v-model="datatable.queryParams.filter.status"></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
            </datatable>

            <CommonModal ref="formModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ formModalTitle.toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <DataForm :formId="formId" :formType="formType" :unihallQuotas="unihallQuotas" v-model="formData" :disabled="formDisabled"
                        @close="formClear(true)" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Component
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'

// Services
import UnihallApplicationsService from '@/services/UnihallApplicationsService';
import UnihallQuotaService from '@/services/UnihallQuotaService';

// Other
import qs from 'qs';

export default {
    name: "UnihallApplications",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        SemestersSelectbox,
        DataForm: () => import("./DataForm")
    },
    metaInfo() {
        return {
            title: this.$t('unihall_applications_title')
        };
    },
    data() {
        return {
            formId: null,
            formType: "",
            formData: {},
            formDisabled: false,
            transferUpdateStatus: "",
            unihallQuotas: [],
            reject: {
                undergraduate_transfer_id: null,
                explanation: null,
            },
            delete: {
                undergraduate_transfer_id: null,
            },
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                hidden: true,
                                callback: (row) => {
                                    this.openForm('show', row);
                                }
                            },
                            {
                                text: this.$t('payment_info_update'),
                                class: 'ri-pencil-line',
                                hidden: true,
                                callback: (row) => {
                                    this.openForm('update', row);
                                }
                            },
                            {
                                text: this.$t('unihall_room_info_update'),
                                class: 'ri-edit-box-line',
                                hidden: true,
                                callback: (row) => {
                                    this.openForm('room-update', row);
                                }
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                callback: (row) => {
                                    this.openForm('delete', row);
                                },
                            },
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        sortable: true,
                        hidden: true
                    },
                    {
                        label: this.$t('academic_year'),
                        field: 'academic_year',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('national_id'),
                        field: 'id',
                        sortable: true,
                        formatFn: (value, row) => {
                            return `${row.student.person.national_id}`;
                        },
                    },
                    {
                        label: this.$t('full_name'),
                        field: 'student.person.name',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return `${row.student.person.name} ${row.student.person.surname}`;
                        },
                    },
                    {
                        label: this.$t('gsm_no'),
                        field: 'person.mobile_tel',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return `${row.person.mobile_tel}`;
                        },
                    },

                    {
                        label: this.$t('gender'),
                        field: 'student.person.gender',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return `${row.student.person.gender}`;
                        },
                    },
                    {
                        label: this.$t('city'),
                        field: 'city',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('room'),
                        field: 'unihall_quota_id',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return this.getRoomName(row.unihall_quota_id)
                        },
                    },
                    {
                        label: this.$t('payment_status'),
                        field: 'payment_status',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return row.payment_status == "E" ? this.$t('payment_success') : row.payment_status == "H" ? this.$t('payment_waiting') : '';
                        },
                    },
                    {
                        label: this.$t('payment_type'),
                        field: 'payment_type',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return row.payment_type !== null ? this.$t(`payment_types_obj.${row.payment_type}`) : '';
                        },
                    },
                    {
                        label: this.$t('student_number'),
                        field: 'student.student_number',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return `${row.student.student_number}`;
                        },
                    },
                    {
                        label: this.$t('status'),
                        field: 'status',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return this.$t(`status_${row.status}`);
                        },
                    },
                    {
                        label: this.$t('application_date'),
                        field: 'application_date',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return !row.application_date ? '' : this.$moment(row.application_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm');
                        },
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    computed: {
        formModalTitle() {
            if (this.formType == "add") {
                return `${this.$t('room')} ${this.$t('add')}`
            }
            return ""
        }
    },
    methods: {
        async getData() {
            const response = await UnihallApplicationsService.get(this.formId)

            if (response.data.success && response.data.data.length > 0) {
                this.formData = response.data.data
            }
        },
        openForm(str, row = null) {
            if (str === "show" || str === "delete") {
                this.formDisabled = true
            }
            if (row) {
                // this.formId = row.id
                this.formData = { ...row }

            }
            this.formId = row.id
            this.formType = str
            this.$refs.formModal.$refs.commonModal.show()
        },
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return UnihallApplicationsService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        // Form
        formClear(close = false) {
            if (close) {
                this.$refs.formModal.$refs.commonModal.hide()
            }
            // this.formId = null
            this.formType = ""
            this.formData = {}
            this.formDisabled = false
            this.getRows();
        },
        getRoomName(id) {
            const find = this.unihallQuotas.find(item => item.id == id)
            if (find) {
                return find.name
            }
            return ""
        },
        getQuotas() {
            const config = {
                params: {
                    sort: 'id',
                    page: 1,
                    limit: 2500
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };
            UnihallQuotaService.getAll(config)
                .then(response => {
                    response.data.data.forEach(item => this.unihallQuotas.push(item))
                })
        }
    },
    async mounted() {
        this.getRows()
        this.getQuotas()
    }
}
</script>
